a {
  color: #000;
  text-decoration: none;
  font-family: monospace;
}

a:visited {
  color: #666;
}

a:hover {
  background-color: #666;
  color: #fff !important;
  text-decoration: underline;
}

.text-center {
  text-align: center;
}
